import { Routes } from '@angular/router';
import { RoleGuard } from '../services/role-guard.service';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...
export const Pages_Routes: Routes = [
    {
        path: '',
        canActivate: [RoleGuard],
        loadChildren: () => import('../../pages/analitics/analitics.module').then(m => m.AnaliticsModule)
    },
    {
        path: 'analitics',
        canActivate: [RoleGuard],
        data: { role: 'analitics'},
        loadChildren: () => import('../../pages/analitics/analitics.module').then(m => m.AnaliticsModule)
    },
    {
        path: 'metrics',
        canActivate: [RoleGuard],
        data: { role: 'metrics'},
        loadChildren: () => import('../../pages/metrics/metrics.module').then(m => m.MetricsModule)
    },
    {
        path: 'employees',
        canActivate: [RoleGuard],
        data: { role: 'employees'},
        loadChildren: () => import('../../pages/employees/employees.module').then(m => m.EmployeesModule)
    },
    {
        path: 'cafeteria',
        canActivate: [RoleGuard],
        data: { role: 'cafeteria'},
        loadChildren: () => import('../../pages/cafeteria/cafeteria.module').then(m => m.CafeteriaModule)
    },
    {
        path: 'news',
        canActivate: [RoleGuard],
        data: { role: 'news'},
        loadChildren: () => import('../../pages/news/news.module').then(m => m.NewsModule)
    },
    {
        path: 'letters',
        canActivate: [RoleGuard],
        data: { role: 'letters'},
        loadChildren: () => import('../../pages/letters/letters.module').then(m => m.LettersModule)
    },
    {
        path: 'credentials',
        canActivate: [RoleGuard],
        data: { role: 'credentials'},
        loadChildren: () => import('../../pages/credentials/credentials.module').then(m => m.CredentialsModule)
    },
    {
        path: 'users',
        canActivate: [RoleGuard],
        data: { role: 'users'},
        loadChildren: () => import('../../pages/users/users.module').then(m => m.UsersModule)
    },
    {
        path: 'payroll',
        canActivate: [RoleGuard],
        data: { role: 'payroll'},
        loadChildren: () => import('../../pages/payroll/payroll.module').then(m => m.PayrollModule)
    },
    {
        path: 'uniforms',
        canActivate: [RoleGuard],
        data: { role: 'uniforms'},
        loadChildren: () => import('../../pages/uniforms/uniforms.module').then(m => m.UniformsModule)
    },
    {
        path: 'receipts',
        canActivate: [RoleGuard],
        data: { role: 'receipts'},
        loadChildren: () => import('../../pages/receipts/receipts.module').then(m => m.ReceiptsModule)
    },
    {
        path: 'checker',
        canActivate: [RoleGuard],
        data: { role: 'checker'},
        loadChildren: () => import('../../pages/checker/checker.module').then(m => m.CheckerModule)
    },
    {
        path: 'checker-bus',
        canActivate: [RoleGuard],
        data: { role: 'checker-bus'},
        loadChildren: () => import('../../pages/checker-bus/checker-bus.module').then(m => m.CheckerBusModule)
    },
    {
        path: 'inventory',
        canActivate: [RoleGuard],
        data: { role: 'inventory'},
        loadChildren: () => import('../../pages/inventory/inventory.module').then(m => m.InventoryModule)
    },
    {
        path: 'surveys',
        canActivate: [RoleGuard],
        data: { role: 'surveys'},
        loadChildren: () => import('../../pages/surveys/surveys.module').then(m => m.SurveysModule)
    },
    {
        path: 'supports',
        canActivate: [RoleGuard],
        data: { role: 'supports'},
        loadChildren: () => import('../../pages/supports/supports.module').then(m => m.SupportsModule)
    },  
    {
        path: 'reports',
        canActivate: [RoleGuard],
        data: { role: 'reports'},
        loadChildren: () => import('../../pages/reports/reports.module').then(m => m.ReportsModule)
    },
    {
        path: 'routes',
        canActivate: [RoleGuard],
        data: { role: 'routes'},
        loadChildren: () => import('../../pages/routes/routes.module').then(m => m.RoutesModule)
    },    
    {
        path: 'vacations',
        canActivate: [RoleGuard],
        data: { role: 'vacations'},
        loadChildren: () => import('../../pages/vacations/vacations.module').then(m => m.VacationsModule)
    },
    {
        path: 'kaizen',
        canActivate: [RoleGuard],
        data: { role: 'kaizen'},
        loadChildren: () => import('../../pages/kaizen/kaizen.module').then(m => m.KaizenModule)
    }                          
]